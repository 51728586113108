import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  Title, 
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/abm-campaign-template.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { ButtonContainer, Contact } from "../../components/button/Button.js"


function AbmCampaignDocument({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="ABM Campaign - Template" 
       description="The goal of this document is to help you build your first ABM campaign in alignment with your Sales and
        Customer success teams."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/abm-campaign-template/">ABM Campaign Setup - Template</a>
            </li>
          </Breadcrumb>
        </Row>
          <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="ABM Campaign - Template" />
            <SubHeading subheading="The goal of this document is to help you build your first ABM campaign in alignment with your Sales and Customer success teams." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/abm-campaign-template" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="ABM Campaign Template" />
          </Column50>
        </Row>
      </AboveFold>
      
      <Section>
        <BlogRow isCentered>
          <TextMedium textMedium="We’ve used the framework in this document to develop and implement ABM campaigns for B2B SaaS companies 
          in various niches."/> 
            <TextMedium textMedium="While there is no one-size-fits-all setup for account based marketing campaigns, we've found this simplified 
            workflow to be very effective in creating alignment between commercial teams, and in keeping the campaign focused on the accounts 
            to target and move through the funnel." />
          <GumroadForm 
                formName="ABM Campaign Template"
                redirectPage="https://socontent.gumroad.com/l/abm-campaign-template"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/abm-campaign-template"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default AbmCampaignDocument